@import (reference) '../../styles/media.less';

.searchSelectWrapper {
  display: flex;
  align-items: center;
}
.searchSelect {
  :global {
    .ant-select {
      margin: 9px;
      width: 240px;
    }
    .ant-select-disabled {
      .ant-select-selector {
        background-color: #fff !important;
      }
    }
    .ant-select-selector {
      border-radius: 5px !important;
      height: 37px !important;
      width: 103% !important;
      z-index: 98;

      .ant-select-selection-item {
        max-width: 212px;
        :local {
          div {
            font-size: 15px;
            padding-left: 10px;
            padding-top: 0.11em;
            color: rgba(0, 0, 0, 0.75);
            div {
              display: flex;
              flex-direction: row;
              line-height: unset;
              max-width: 199px;

              strong {
                font-weight: 400;
                padding-right: 3px;
                max-width: 193px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }

              span {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                flex: 1;
                min-width: 0;
              }
            }
          }
        }
      }
      .ant-select-selection-placeholder.ant-select-selection-placeholder {
        opacity: 1;
        padding-top: 3px;
        font-size: 15px;
        letter-spacing: 0.45px;
        padding-left: 22px;
        color: rgba(0, 0, 0, 0.55);
      }

      .ant-select-selection-search-input {
        height: 35px !important;
        padding-left: 19px !important;
      }
    }
    .ant-select-arrow {
      transition: left ease-in-out 200ms;
      right: unset;
      left: 12px;
      color: rgba(0, 0, 0, 0.55);
      font-size: 15px;
      z-index: 98;
    }
    .ant-select-clear {
      z-index: 99;
      right: 3px;
    }
  }
}

.searchSelect__open {
  :global {
    .ant-select {
      width: 240px;
    }
    .ant-select-arrow {
      left: 10px;
    }
  }
}

// fixes ant bug that makes the clickable area of closed search 17px wide
.searchSelect__closed {
  :global {
    .ant-select-arrow .anticon:not(.ant-select-suffix) {
      width: 37px;
      height: 37px;
    }
  }
}

.closeIcon {
  z-index: 99;
  opacity: 0;
  width: 24px;
  height: 24px;
  transition: opacity ease-in-out 200ms;
}

.closeIcon__open {
  opacity: 1;
}

.result {
  display: flex;
  align-items: center;
}
.resultText {
  font-size: 15px;
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 210px;
  }
}

:global {
  .mapboxgl-ctrl-attrib-button {
    display: none !important;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;